import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from 'axios'
import {
  Message,
  MessageBox
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import moment from 'moment' //导入文件

Vue.prototype.$moment = moment; //赋值使用


Vue.config.productionTip = false;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$axios = axios

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");